
.row {
    margin-left:20px;
}

.row__posters {
    display:flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
 }

 .row__posters::-webkit-scrollbar {
   display:none;
 }
 


.row__poster {
    width: 100%;
    object-fit: contain;
    max-height: 400px;
    margin-right: 10px;
    transition: transform 450ms;
}


.row__poster:hover {
    transform: scale(1.08);
}

.row__posterLarge {
    max-height: 300px;
}

.row__posterLarge:hover {
    transform: scale(1.10);
}


