.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    background-color: #090b13;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
    letter-spacing: 16px;
    z-index: 3;
}

.header__logo {
    object-fit: contain;
    width: 100px;
    margin:0 20px;
    margin-top:18px;
}

.header__menu {
    text-decoration: none;

}

.header__menuIcon {
    
    color: white;
    font-size: 100px;
}

.header__icons {
display: flex;
align-items: center;

}

.header__icons > a{
    text-decoration: none;

    }